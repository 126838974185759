.sidebar {
    display: flex;
    flex-direction: column;
    flex: 0.35;
}

.sidebar__header{
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-right: 1px solid lightgray;
}

.sidebar__headerRight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 10vw;
}

.sidebar__headerRight > .MuiSvgIcon-root {
    margin-right: 2vw;
    font-size: 24px !important;
}

.sidebar__search{
    display: flex;
    align-items: center;
    background-color: #f6f6f6;
    height: 39px;
    padding: 10px;
}

.sidebar__chats{
    flex: 1;
    background-color: #818181;
	overflow-y: auto;
	overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}
*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.containers{
    display:flex;
    width: 100%;
}
.capyrights_para{
    margin: 0%;
}
.recent{
    color: #798699;
    font-size: 12px;
    margin-bottom: 0px;
    padding: 14px 14px 14px 20px;
    /* color: #263238; */
    font-weight: 500;
}
.setting_con{
    animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
.top_heder_part{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    justify-content: space-between;
    width: 100%;
}
.create_chat{
    width: 100%;

    margin:15% 0% 20% 0%;
    display: flex;
    justify-content: center;

}
.create_chat_btn{
    color: #afadad;
    border: none;
    outline: none;
    border-radius: 7px;
    width: 100%;
    height: 40px;
    font-size: 14px;

}
.create_chat_btn.dark-theme{
    background: #323840;
}
.create_chat_btn.light-theme{
    background: whitesmoke;
}
.header{
    padding: 0% 2% 0% 2%;
    width: 100%;
    height: 55px ;
    background-color: #111111;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
main{
    width: 100%;
}
.sidebars{
    background: #111111;
    color: #fff;
    height: 100vh;
    overflow-y: scroll;
    width: 250px;
    padding: 0% 1.3% 0% 1.8%;
    transition: all 0.5s;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    /* border: 5px solid darkgreen; */
}
.sidebars::-webkit-scrollbar {
    width: 2px;
}
.sidebar_div{
    width: 100%;
    padding: 0% 10% 0% 2%;
    height: auto;
}

.sidebars::-webkit-scrollbar-track {
    background-color: transparent;
}

.sidebars::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.room_navigator{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 0% 0% 0% 4%;
    width: 100%;
    margin-bottom: 0.2rem;
}
.top_section{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding:20px 15px;
}
.image_div{
    filter: invert(0);
    margin-right: 1rem;
}
.logo{
    font-size: 10px;
    /* width */
    width: 170px ;
    height: 50px;
}
.logos{
    font-size: 10px;
}
.bars{
    display: flex;
    font-size: 15px;
    margin-left: 50px;

}
.link{
    display: flex;
    color: #a8a6a6;
    width: 100%;
    gap: 15px;

    transition: all 0.5s;
    text-decoration: none;
    list-style: none;
    align-items: center;
    gap: 1rem;
}
.link.dark-theme:hover{
    background: #323840;
    color: #afadad;
    transition: all 0.5s;
    border-radius: 7px;
}
.link.light-theme:hover{
        background:whitesmoke;
        color: #afadad;
        transition: all 0.5s;
        border-radius: 7px;

}
.dedraw{
    background-color: yellow;
    color: #afadad;
    border: 7px solid red;

}
.active{
    border-radius: 7px;
    color: #afadad;
    display: flex;
    align-items: center;
}
.active.dark-theme{
    background: #323840;
}
.active.light-theme{
    background: whitesmoke;
}
.link .deleteBtn{
    display: none;
}
.active .deleteBtn{
    display: block;
}
.deactive{
    background-color: #111111;
}
.icon{
    font-size: 14px;
    list-style: none;
    text-decoration: none;
    width: auto;
}


.link_text{
    font-size: 14px;
    list-style: none;
    text-decoration: none;
    width: 100%;
}
.active .link_text{
    text-overflow: clip;
    display: flex;
    align-items: center;
}
.link_text_desc{
    font-size: 14px;
    text-overflow: ellipsis;
    margin: 0%;
    padding: 0%;
    display: flex;
    align-items: center;
    font-family: 'Poppins', 'Roboto', sans-serif;
    text-overflow: ellipsis;
    width: 100%;
}
.icon_btn_appbar{
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0rem 1rem 0rem 1rem;
}


.Right_draw_side{
    width: 100%;

}
.Right_draw_side>ul{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    text-decoration: none;
}
.Right_draw_side>ul>li{
    color: #a8a6a6;
    text-decoration: none;
    list-style: none;
    width: 100%;
    list-style: none;
    margin: 0%;
    padding: 0%;

}
.Right_draw_side>ul>li>span,
.download_pdf{
    color: #a8a6a6;
    text-decoration: none;
    list-style: none;
    display: flex;
    gap: 0.5rem;
    cursor: pointer;
    align-items: center;
    border-bottom: 1px solid rgb(53, 52, 52);
    padding: 0.3rem 0rem 0.3rem 0rem;
    width: 100%;
    height: 55px;
}

.selection_bar{
    color: #a8a6a6;
    text-decoration: none;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    cursor: pointer;
    align-items: flex-start;
    border-bottom: 1px solid rgb(53, 52, 52);
    padding: 0.3rem 0rem .9rem 0rem;
    width: auto;
    height: auto;
}
.selection_bar span{
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.input-new-chat, .input-new-chat:focus {
    background-color: #272c33;
    color: #747d8a;
}

.closebtn-new-chat{
    background-color: white;
    color: black;
    margin-left: 10px;
}

.header-modal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #e9ecef;
    border-color: rgba(120, 130, 140, 0.13);
}

.footer-modal{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
    border-top: none;
}

.new-chat-close-btn:hover {
    cursor: pointer;
}

.avatar-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avatar-title {
    color: white;
    margin-right: 10px;
}

.header-drawer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    width: 14%;
}

.download_flex{
    color: rgb(176, 170, 159);
    list-style-image: initial;
    padding: 10px;
}

.download_flex:hover{
    cursor: pointer;
    text-decoration: underline;
}

.icon-download{
    color: rgb(109,165,54);
    font-size: 20px;
    margin-right: 5px;
}
.sidebarChat{
    display: grid;
    padding: 10px; 
    grid-template-columns: 1fr 20px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
}

.activesidebarChat{
    background-color: #ebebeb;
    display: grid;
    padding: 10px; 
    grid-template-columns: 1fr 20px;
    cursor: pointer;
    border-bottom: 1px solid #f6f6f6;
}
.rooms_carrier{
    display: flex;
}


.sidebarChat > a:hover{
    background-color: #ebebeb;
}

.sidebarChat:hover{
    background-color: #ebebeb;
}

.sidebarChat > a {
    display: flex;
}

.sidebarChat__info > h2 {
    font-size: 15px;
    margin-bottom: 4px;
    color: black;
}

.sidebarChat__info > p {
    font-size: 12px;
    color: black;
}

.sidebarChat__info{
    margin-left: 15px;
}

.sidebarChat > h2 {
    font-size: 20px;
    font-weight: bolder;
}

.sidebarChat > button {
    outline: 0;
    grid-gap: 8px;
    align-items: center;
    background: 0 0;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    flex-shrink: 0;
    font-size: 16px;
    gap: 8px;
    justify-content: center;
    line-height: 1.5;
    overflow: hidden;
    padding: 12px 16px;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: all .14s ease-out;
    white-space: nowrap;
    width: 100%;
}

.sidebarChat > button:hover {
    box-shadow: 4px 4px 0 #000;
    transform: translate(-4px,-4px);
}



.MuiSvgIcon-root{
    color: #a19f9f;
    cursor: pointer;
}

.MuiSvgIcon-root:hover{
    color: white;
    cursor: pointer;
}


