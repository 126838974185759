.show-models-para{
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 30px;
    border-bottom: 1px dotted #bebbbb;
    padding: 10px;
}

.show-models-para .MuiSvgIcon-root:hover{
    background-color: #bebbbb;
    cursor: pointer;
}

.models-para-text {
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.show-del-btn:hover {
    cursor: pointer;
    color: #6EA636;
}
.new-chat-close-btn{
    cursor: pointer;
}
.close_icon_modal{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.7rem;
}
.modal_papers{
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Replace with actual theme shadow value */
    padding: 16px 32px 24px 16px;/* Replace with actual theme spacing values */
    width: auto;
    /* overflow-x: scroll; */
    

}