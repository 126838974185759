.top {
    position: relative;
    background-color: #232223;
    margin: 20px auto;
    width: 80%;
    height: auto;
    border: 4px solid #71ac37;
    border-radius: 10px;
    text-align: center;
    padding: 45px 0 40px 0;
}
.log_in_btn{
    width: 100%;
    display: flex;
    align-items: center;
    /* gap: 0.1 rem; */
    /* border: 2px solid red; */
}
.client_login_form{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 45%;
}

.bot {
    text-align: center;
    height: 225px;
    width: 225px;
    position: relative;
    border: 5px solid #80808021;
    border-radius: 100%;
    margin: 0 auto;
}

.img-bot {
    position: absolute;
    width: 50%;
    top: 18%;
    right: 24%;
}

.start-btn {
    border: none;
    background-color: #71ac37;
    font-size: 14px;
    border-radius: 20px;
    outline: none;
    padding: 5px 15px 5px 15px;
}
.start-btn:hover{
    background-color: #5a832e;
}

.start-btn:focus {
    box-shadow: none !important;
    border: none !important;
    outline: none;
}

.custom-form {
    background-color: #434243;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 60%;
    margin: 0 auto;
}

.custom-form .form-group {
    margin-bottom: 20px;
}

.custom-form label {
    font-weight: bold;
}

.custom-form input[type='email'],
.custom-form input[type='password'] {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
}

.custom-form button[type='submit'] {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
}

.custom-form button[type='submit']:hover {
    background-color: #0056b3;
}

.MuiSelect-selectMenu {
    padding-left: 0.9rem;
}


/*============================================Media Query=============================================*/

@media only screen and (min-device-width:360px) and (max-device-width:767px) {
    .chat__body {
        padding: 12px;
    }

    .top {
        width: 90%;
        height: 480px;
    }

    .parent-top {
        margin-top: 20px;
    }

    .copyright {
        margin: 65px auto 0 auto;
    }

    .top-second {
        width: 90%;
        height: 453px;
        max-height: 490px;

    }

    .bottom-div {
        width: 90%;
    }

    .f-control {
        width: 90%;
    }

    .send {
        right: 20%;
    }

    .chat__message {
        display: flow-root;
        position: relative;
        margin-top: 15px;
    }

    .top-second {
        text-align: unset;
    }

    .chat__name {
        float: left;
    }

    .chat__content {
        left: 33px;
        position: absolute;
    }

    .chat__dark {
        left: 33px;
        /* top: 35px; */
    }

    /* .chat__namemob{
    margin-top: 35px;
} */
    .chat__message .MuiFormControl-root {
        /* margin-top: 45px; */
        float: right;
    }

    .boatimgmob {
        position: unset;
        top: 35px;
    }

    .msg-price {
        right: 30px;
    }

    .tts-button {
        right: 4px;
    }






}

/* @media only screen and (min-device-width:360px) and (max-device-width:740px)
{
    .top-second{
        width: 90%;
        height: 538px;
        max-height: 767px;
    }
    .copyright {
        margin: 81px auto 0 auto;
    }
} */
@media only screen and (min-device-width:375px) and (max-device-width:667px) {
    .top-second {
        width: 90%;
        height: 468px;
        max-height: 767px;
    }

    .copyright {
        margin: 75px auto 0 auto;
    }
}

@media only screen and (min-device-width:390px) and (max-device-width:844px) {
    .top-second {
        width: 90%;
        height: 633px;
        max-height: 767px;
    }

    .copyright {
        margin: 88px auto 0 auto;
    }
}

@media only screen and (min-device-width:414px) and (max-device-width:896px) {

    .top-second {
        width: 90%;
        height: 690px;
        max-height: 690px;
    }

    .copyright {
        margin: 93px auto 0 auto;
    }

    .mic {
        bottom: -10.5%;
    }

    .send {
        bottom: -10.5%;
    }



}

@media only screen and (min-device-width:768px) and (max-device-width:1024px) {
    .top-second {
        width: 90%;
        height: 790px;
        max-height: 856px;
    }

    .heading {
        width: 90%;
    }

    .menu-div {
        width: 35%;
        right: 5%;
    }

    .bottom-div {
        width: 90%;
    }

    .f-control {
        bottom: -8%;
        width: 90%;
    }

    .copyright {
        margin: 80px auto 0 auto;
    }

    .mic {
        bottom: -6.7%;
        right: 8%;
    }

    .send {
        bottom: -6.7%;
    }




}

@media only screen and (min-device-width:1025px) and (max-device-width:1365px) {
    .chat__body {
        padding: 12px;
    }

    .send img {
        width: unset !important;
    }

    .mic img {
        width: unset !important;
    }

    .send {
        right: 15%;
    }

    .top {
        width: 80%;
        height: 81vh;
    }

    .top-second {
        width: 95%;
        height: 65vh;
        max-height: 77vh;
        margin-bottom: 20px;
    }

    .copyright {
        margin: 85px auto 0 auto;
        font-size: 20px !important;
    }

    .chat__content {
        font-size: 25px;
        padding: 25px;
    }

    .chat__dark {
        font-size: 25px;
        padding: 25px;
        line-height: unset;
    }

    /* .MuiInputLabel-animated {
        font-size: 32px !important;
    } */

    .tts-img {
        width: 40px;
    }

    .tts-button {
        top: -36px;

    }

    .msg-price span {
        font-size: 25px;

    }

    .msg-price {
        top: -31px;
        right: 60px;
    }

    .selectopt {
        font-size: 20px !important;
    }

    .chat__message {
        margin-bottom: 50px;
        margin-top: 20px;
    }

    /* .MuiMenuItem-root {
        font-size: 1.5rem !important;
    } */

    /* .MuiPaper-root {
        top: 310px !important;
        left: 1090px !important;
    } */

    .heading {
        width: 95%;
    }

    .menu-div .menu-text {
        font-size: 25px;
    }

    .menu-div {
        width: 25%;
        max-height: 525px;
        right: 2.5%;
    }
    .img-outerbot {
        width: 8.5%;
    }
    .f-control {
        height: 65px;
        border-radius: 40px;
        font-size: 25px;
        padding: 0 165px 0 20px;
    }
    .bot{
        width: 130px;
        height: 130px;
    }
}


@media only screen and (min-device-width:1920px) {
    .send img {
        width: unset !important;
    }

    .mic img {
        width: unset !important;
    }

    .send {
        right: 15%;
    }

    .top {
        width: 95%;
        height: 79vh;
    }

    .bot {
        height: 500px;
        width: 500px;
    }

    .top-second {
        width: 95%;
        height: 70vh;
        max-height: 77vh;
    }

    .copyright {
        margin: 105px auto 0 auto;
        font-size: 20px !important;
    }

    .chat__content {
        font-size: 25px;
        padding: 25px;
    }

    .chat__dark {
        font-size: 25px;
        padding: 25px;
        line-height: unset;
    }

    /* .MuiInputLabel-animated {
        font-size: 32px;
    } */

    .MuiSelect-select.MuiSelect-select {
        font-size: 25px;
        margin-top: 25px;
    }

    .tts-img {
        width: 40px;
    }

    .tts-button {
        top: -36px;

    }

    .msg-price span {
        font-size: 25px;

    }

    .msg-price {
        /* top: -31px; */
        right: 60px;
        /* background: rgb(235, 240, 243) */
    }

    .selectopt {
        font-size: 20px !important;
    }

    .chat__message {
        margin-bottom: 50px;
        margin-top: 20px;
    }

    .MuiMenuItem-root {
        font-size: 1.5rem !important;
    }

    /* .MuiPaper-root {
        left: 1646px !important;
    } */

    .heading {
        width: 95%;
    }

    .menu-div .menu-text {
        font-size: 25px;
    }

    .menu-div {
        width: 25%;
        max-height: 700px;
        right: 2.5%;
    }

    .img-outerbot {
        width: 8.5%;
    }

    .f-control {
        height: 65px;
        border-radius: 40px;
        font-size: 25px;
        padding: 0 165px 0 20px;
    }





}